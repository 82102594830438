

import Vue from 'vue'
import Error from './Error.vue'


// Vue config
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.productionTip = true

// eslint-disable-next-line no-new
new Vue({
  el: '#root',
  components: { Error },
  template: '<Error/>'
})
