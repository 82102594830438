<template lang="pug">
.error-page
  aside
    img(src="require('./background.png')")
  main
    img.logo(src="@/shared/assets/img/ovhcloud_logo_blue.png")
    .view
      .number
        span.green 4
        span.blue 0
        span.red 4
      h1 Page not found
      p
        span The page you were looking for could not be found, it might have been removed
        br
        span renamed, or did not exist in the first place.
      br
      br
      a(:href="url") Return to Data Platform
</template>

<script>
import Config from '@/shared/Config'
export default {
  components: {
  },
  data () {
    return {
      config: {}
    }
  },
  computed: {
    url () {
      return this.config.FPUI
    }
  },
  async mounted () {
    this.config = await Config()
  }
}
</script>

<style lang="less">
  html,body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  .error-page {
    overflow: hidden;
    font-family: 'Source Sans Pro';
    height: 100%;
    aside {
      width: 483px;
      height: 100%;
      float: left;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    main {
      position: absolute;
      right:0;
      bottom: 0;
      top: 0;
      left: 40%;
      overflow: auto;
      height: 100%;
      padding: 30px 30px 15px 85px;

      .logo {
        margin-left: 0px;
        width: 150px;
      }
      .number {
        font-weight: bold;
        font-size: 200px;
        .blue {
          color: @blue_flash;
        }
        .green {
          color: @green;
        }
        .yellow {
          color: @yellow;
        }
        .red {
          color: @red;
        }
      }
      h1 {
        font-size: 54px;
        line-height: 54px;
        margin-top: 25px;
        margin-bottom: 0;
        font-weight: 700;
      }
      p {
        color: @grey;
      }

      .view {
        padding-top: 100px;
        padding-bottom: 100px;
      }
    }
    @media screen {
      @media (max-width: 1234px) {
        aside {
          width: 30%;
        }
        main {
          left: 30%;
        }
      }
      @media (max-width: 1050px) {
        aside {
          display:none;
        }
        main {
          left: 0%;
        }
      }
    }
  }
</style>
